enum Providers {
  STM = 'STM',
  MSG91 = 'MSG91',
  VALUEFIRST = 'ValueFirst',
  OneXtel = 'OneXtel',
  KARIX = 'KARIX',
  WONDER_MAIL = 'WONDER_MAIL',
  WATI = 'WATI',
  ICS = 'ICS',
  QIKBERRY = "QIKBERRY"
}

export default Providers;
