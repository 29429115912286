/* eslint-disable @typescript-eslint/explicit-function-return-type */
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { capitalize, compact, get, map, random, reduce } from 'lodash';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  createLeadReport,
  getLeadReportAdditionalFields,
  getLeadSources,
  getStoreUsers
} from '../../../services/TenantReports/LeadReport.service';
import { getCurrentUser } from '../../../services/user.service';
import {
  getStoreTagOptionsByStoreOptions,
  getStoresWithAnyTags
} from '../../../services/store.service';
import { RefreshOutlined } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Option {
  label: string;
  value: string;
  type: string;
}

interface LeadReportFilter {
  startDate?: string;
  endDate?: string;
  followUpFrom?: string;
  followUpTill?: string;
  stores?: string[];
  storeTags?: string[];
  storePersons?: string[] | any;
  sources?: string[] | { key: string; doc_count: number }[];
  additionalFields: Option[];
}

const LeadReportInitialState: LeadReportFilter = {
  startDate: moment()
    .subtract(1, 'month')
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  followUpFrom: '',
  followUpTill: '',
  stores: [],
  storeTags: [],
  storePersons: [],
  sources: [],
  additionalFields: []
};

interface ReportActionsProps {
  setRefreshView: (id: string) => void;
  type: string;
}

const LeadReportFilter: FC<ReportActionsProps> = ({ setRefreshView, type }) => {
  const [filters, setFilters] = useState<LeadReportFilter>(
    LeadReportInitialState
  );
  const [selectedBusinessId, setSelectedBusinessId] = useState<
    number | undefined
  >(undefined);
  const [filterValues, setFilterValues] = useState<
    LeadReportFilter | undefined
  >(undefined);
  const [getStores, setGetStores] = useState<any[]>([]);
  const [storeOption, setStoreOption] = useState<any[]>([]);
  const [storeTagsOption, setStoreTagsOption] = useState<any[]>([]);
  const [tenantStore, setTenantStore] = useState<any[]>([]);

  const user = useCurrentUser();

  const handleDateChange = (date: any, name: string) => {
    setFilters({ ...filters, [name]: date });
  };

  const changeTenantStoreFormat = () => {
    const options = getStores.map((store: any) => {
      return {
        id: store.id,
        label: capitalize(store.storeNameAlias || store.store),
        value: store.store,
        tags: compact(
          map(store.tags, tag => {
            return { value: tag.value, type: tag.type };
          })
        )
      };
    });
    return options;
  };

  const handleTagSelect = (storeTags: any) => {
    if (storeTags && storeTags.length > 0) {
      const data = getStoresWithAnyTags(tenantStore, storeTags);
      setStoreOption(data || []);
    } else {
      const data = changeTenantStoreFormat();
      setStoreOption(data || []);
    }
    setFilters({
      ...filters,
      storeTags
    });
  };

  useEffect(() => {
    if (filters.storeTags && filters.storeTags.length > 0) {
      const filteredStores = map(
        getStoresWithAnyTags(
          getStores,
          filters.storeTags.map(sv => {
            return { value: sv };
          })
        ),
        (store: any) => {
          return {
            id: store.id,
            label: capitalize(store.storeNameAlias || store.store),
            value: store.store,
            tags: compact(
              map(store.tags, tag => {
                return { value: tag.value, type: tag.type };
              })
            )
          };
        }
      );
      setStoreOption(filteredStores);
    } else {
      const unFilteredStores = changeTenantStoreFormat();
      setStoreOption(unFilteredStores);
    }
    setFilters({
      ...filters,
      stores: []
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.storeTags]);

  useEffect(() => {
    const data: any[] = changeTenantStoreFormat();
    setTenantStore([...data]);
    const getStore = getStores.map((store: any) => {
      return {
        id: store.id,
        label: store.store,
        value: store.store
      };
    });

    setStoreOption([...getStore]);
    const getOptions = getStoreTagOptionsByStoreOptions(data);
    setStoreTagsOption([...getOptions]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStores]);

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const paramsBuId = +urlParams.get('bu')! || 0;
      setSelectedBusinessId(paramsBuId);
      Promise.all([
        getLeadSources(paramsBuId),
        getStoreUsers(['lead_create'], paramsBuId),
        getLeadReportAdditionalFields(paramsBuId)
      ])
        .then(([leadSources, storePersons, additionalFields]) => {
          setFilterValues(prevFilterValues => ({
            ...prevFilterValues,
            sources: leadSources || prevFilterValues?.sources,
            storePersons: storePersons || prevFilterValues?.storePersons,
            additionalFields:
              additionalFields || prevFilterValues?.additionalFields || []
          }));
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [user]);

  useEffect(() => {
    getCurrentUser().then(res => {
      return setGetStores([...res.tenant_stores]);
    });
  }, []);

  const handleGenerateReport = (): void => {
    createLeadReport(type, filters, selectedBusinessId).then((res: any) => {
      const jobId = get(res, 'jobId', '');
      setRefreshView(jobId);
    });
  };

  const currentDate = moment(new Date()).toDate();
  return (
    <div
      style={{
        margin: '30px 0',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1rem'
      }}
    >
      <div style={{ display: 'flex', gap: '1rem', alignItems: 'flex-start' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ width: '150px' }}
            label="Created From"
            maxDate={currentDate}
            value={filters.startDate}
            onChange={e => handleDateChange(e, 'startDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            label="Created Till"
            style={{ width: '150px' }}
            maxDate={currentDate}
            value={filters.endDate}
            onChange={e => handleDateChange(e, 'endDate')}
            variant="inline"
            format="yyyy-MM-dd"
          />
          <KeyboardDatePicker
            style={{ width: '150px' }}
            label="Follow Up From"
            value={filters.followUpFrom}
            onChange={e => handleDateChange(e, 'followUpFrom')}
            variant="inline"
            format="yyyy-MM-dd"
            error={false}
            helperText=""
            InputLabelProps={{
              shrink: filters?.followUpFrom !== ''
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            disableToolbar
          />
          <KeyboardDatePicker
            label="Follow Up Till"
            style={{ width: '150px' }}
            value={filters.followUpTill}
            minDate={filters.followUpFrom}
            onChange={e => handleDateChange(e, 'followUpTill')}
            variant="inline"
            format="yyyy-MM-dd"
            error={false}
            helperText=""
            InputLabelProps={{
              shrink: filters?.followUpTill !== ''
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            disableToolbar
          />
        </MuiPickersUtilsProvider>
        <FormControl style={{ width: '150px' }}>
          <InputLabel id="sourcesLabel">Sources</InputLabel>
          <Select
            multiple
            defaultValue=""
            labelId="sources"
            value={filters?.sources}
            onChange={(event: any) => {
              setFilters({
                ...filters,
                sources: event.target.value
              });
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            }}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {map(filterValues?.sources, (source: any) => (
              <MenuItem
                selected={filters.sources?.includes(source)}
                value={source}
                key={source}
              >
                <Checkbox
                  style={{ color: 'rgb(242, 117, 95)' }}
                  checked={filters?.sources?.includes(source)}
                />
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: '180px' }}>
          <InputLabel id="storePersonsLabel">Store Persons</InputLabel>
          <Select
            multiple
            defaultValue=""
            labelId="storePersons"
            value={filters?.storePersons}
            onChange={(event: any) => {
              setFilters({
                ...filters,
                storePersons: event.target.value
              });
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            }}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {map(filterValues?.storePersons, (storePerson: any) => (
              <MenuItem
                selected={filters.storePersons?.includes(storePerson?.email)}
                value={storePerson?.email}
                key={storePerson?.email}
              >
                <Checkbox
                  style={{ color: 'rgb(242, 117, 95)' }}
                  checked={filters?.storePersons?.includes(storePerson?.email)}
                />
                {storePerson?.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ width: 180, marginRight: '10px' }}>
          <InputLabel id="lead-report-filter-by-store-tags-label">
            Filter Stores by Tags
          </InputLabel>
          <Select
            multiple
            value={filters?.storeTags}
            label={'Filter Stores by Tags'}
            id={'Filter Stores by Tags'}
            onChange={e => handleTagSelect(e.target.value)}
            renderValue={() => filters?.storeTags?.join(', ')}
          >
            {reduce(
              storeTagsOption,
              (listItems: any[], sto, key) => [
                ...listItems,
                <ListSubheader
                  style={{ backgroundColor: 'white', pointerEvents: 'none' }}
                  color="primary"
                  key={key}
                >
                  {sto.label}
                </ListSubheader>,
                ...map(sto.options, (o, ok) => (
                  <MenuItem
                    style={{
                      backgroundColor: filters?.storeTags?.includes(o.value)
                        ? 'rgb(242, 117, 95)'
                        : 'white'
                    }}
                    key={`${key}-${ok}`}
                    value={o.value}
                  >
                    {capitalize(o.value)}
                  </MenuItem>
                ))
              ],
              []
            )}
          </Select>
        </FormControl>
        <FormControl style={{ width: 180, marginRight: '10px' }}>
          <InputLabel id="lead-report-store-label">Stores</InputLabel>
          <Select
            multiple
            defaultValue=""
            labelId="stores"
            value={filters?.stores}
            onChange={(event: any) => {
              setFilters({
                ...filters,
                stores: event.target.value
              });
            }}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              }
            }}
            renderValue={() => filters?.stores?.join(', ')}
          >
            {map(storeOption, (currentStoreOption: any) => (
              <MenuItem
                selected={filters.stores?.includes(currentStoreOption?.value)}
                value={currentStoreOption?.value}
                key={currentStoreOption?.value}
              >
                <Checkbox
                  style={{ color: 'rgb(242, 117, 95)' }}
                  checked={filters?.stores?.includes(currentStoreOption?.value)}
                />
                {currentStoreOption?.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {filterValues?.additionalFields && type === 'overall_lead_report' && (
        <Autocomplete<Option, true, false, false>
          id="additional-fields"
          multiple={true}
          options={filterValues?.additionalFields || []}
          style={{ width: 300 }}
          groupBy={option => option.type}
          getOptionLabel={option => option.label}
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={params => (
            <TextField
              {...params}
              label="Additional Fields"
              variant="outlined"
            />
          )}
          selectOnFocus={false}
          onChange={(_, value) => {
            setFilters({
              ...filters,
              additionalFields: value
            });
          }}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={handleGenerateReport}
        style={{ height: '3rem' }}
      >
        Generate Report
      </Button>
      <Button
        onClick={() => setRefreshView(`${random(10000)}`)}
        color="primary"
      >
        <RefreshOutlined
          style={{ marginRight: '5px' }}
          color="primary"
          fontSize="medium"
        />
        Refresh
      </Button>
    </div>
  );
};

export default LeadReportFilter;
